import type { AuthUserReturn } from '~/api-core/Account/AuthUser'
import type { CoreFetch } from '~/types/CoreFetch'

/**
 * Log into the core service with your authentication service token (it's a jwt).
 * This works with sso and classic email login's, but you need to be logged in to the auth service to get your token.
 *
 * @param coreFetch - The coreFetch transporter.
 * @returns The api call function.
 */
export function provideLoginSession(coreFetch: CoreFetch) {
  return function (token: string) {
    return coreFetch.$post<AuthUserReturn>('/auth/login/session/', {
      token,
    })
  }
}

/**
 * Log out of the core service.
 *
 * @param coreFetch - The coreFetch transporter.
 * @returns The api call function.
 */
export function provideLogoutSession(coreFetch: CoreFetch) {
  return function () {
    return coreFetch.$post<void>('/auth/logout/')
  }
}
